<template>
  <div class="at-someone-content">
    <Toolbar
      :class="{'hide-tool-bar': from === 'detail'}"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      :style="{'min-height': '26px', 'overflow-y': 'hidden', 'margin-top': from !== 'detail' ? '12px' : 0,}"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="onChange"
      @onFocus="editorFocusHandler"
      @onBlur="editorBlurHandler"
      @customPaste="customPaste"
    />
    <mention-modal
      v-if="isShowModal"
      :from="from"
      :atType="atType"
      @hideMentionModal="hideMentionModal"
      @insertMention="insertMention"
      @editorFocusHandler="editorFocusHandler"
      @editorBlurHandler="editorBlurHandler"
    />
    <!-- AI识图 -->
    <AIModal
      v-if="isShowAIModal"
      :from="from"
      :topNumber="topNumber"
      :top="top"
      :right="right"
      :bottom="bottom"
      :left="left"
      :fileStream="fileStream"
      @replaceImg="handleReplaceImg"
      @addText="handleAddText"
      @closeAIModal="handleCloseAIModal"
    />
  </div>
  <teleport v-if="emojiTeleport&&onMountedSuccess" :to="'#'+emojiTeleport">
  　　　<a-trigger
        :duration="300"
        position="tr"
        :popup-translate="[15, -8]"
        v-model:popup-visible="triggerVisible"
        >
          <a-tooltip :disabled="!isMiniBtn" content="表情">
            <div v-if="!isMiniBtn" class="emoji-icon-wrap">
              <i class="iconfont emoji-icon icon-icon_emoji " />
              <span class="text">表情</span>
            </div>
            <div v-else class="emoji-icon-wrap">
              <i class="iconfont emoji-icon icon-icon_emoji " style="margin-right: 0" />
            </div>
          </a-tooltip>
          
          <template #content>
            <EmojiCom  @selectEmoji="emojiChange"/>
          </template>
      </a-trigger>
  </teleport>

  <a-trigger
    v-if="showEmoji"
    :duration="300"
    position="tr"
    :popup-translate="[15, -8]"
    v-model:popup-visible="triggerVisible"
    >
    <i v-if="showEmoji" class="iconfont emoji-icon icon-icon_emoji " style="line-height:22px "/>
    <template #content>
      <EmojiCom  @selectEmoji="emojiChange"/>
    </template>
  </a-trigger>

</template>

<script>
import { Tools } from '@/utils/browser.js'
import { position, offset } from 'caret-pos'
import { searchFilesByUser, querySelfFileNoId } from '@/services/investment/document.js'
import fileIcon from '@/components/fileIcon.vue'
// import { isHttpUrl } from '@/utils/url.js'
import { Boot } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import mentionModule from '@wangeditor/plugin-mention'
import MentionModal from './MentionModal.vue'
import AIModal from './AIModal.vue'
import EmojiCom from '@/views/beforeInv/edit/indicators/components/emojiCom.vue'
import emojiType from '@/views/beforeInv/edit/indicators/components/emojiTypeList.js'
// 注册插件
Boot.registerModule(mentionModule)

export default {
name: "AtSomeone",
components:{
  fileIcon,
  Editor,
  Toolbar,
  MentionModal,
  AIModal,
  EmojiCom
},
props: {
  
  recordFrom: {
    type: String,
    default: '',  //有值标识来自于台账, 没值表示来自于详情页
  },
  from: {
    type: String,
    default: '', // followRecord跟进记录, detail, message
  },
  placeholder: {
    type: String,
    default: '添加项目跟进，记录重要进展',
  },
  id: {
    type: String,
    default: 'editor'
  },
  record: {
    type: String,
    default: ''
  },
  height:{
    type: String,
    default: '30px'
  },
  minHeight: {
    type: String,
    default: '66px'
  },
  maxHeight: {
    type: String,
    default: '171px'
  },
  atType:{    //支持@的类型，file文件，user人员
    type: Array,
    default: ['file', 'user']
  },
  enablePasteImg:{    //支持粘贴图片，默认可以
    type: Boolean,
    default: true
  },
  isRemark:{    //是否是备注
    type: Boolean,
    default: false
  },
  rejectBlur: { // 阻止失焦事件传递
    type: Boolean,
    default: false,
  },
  showEmoji: {// 是否显示emoji
    type: Boolean,
    default: false,
  },
  isMiniBtn: {
    type: Boolean,
    default: false,
  },
  emojiTeleport: {
    type: String,
    default: ''
  },
},
data() {
  return {
    showFlag: 'hidden',
    userLoading: false,
    browserType: Tools.browserType(),
    isShowModal: false,
    editor: null,
    html: '',
    toolbarConfig: {
      // modalAppendToBody: true,
      toolbarKeys: [
        'bold',
        'through',
        'italic',
        'underline',
        'bulletedList',
        'numberedList',
        'blockquote',
        // 'color',
        // 'bgColor',
        "insertLink",
      ]
    },
    editorConfig: {
      placeholder: this.placeholder,
      autoFocus: false,
      // maxLength: 1000,
      hoverbarKeys: { // 链接和图片清楚Hoverbar
        'link': {
          menuKeys: []
        },
        'image': {
          menuKeys: []
        },
        'pre': {
          menuKeys: []
        },
        'divider': {
          menuKeys: []
        },
        'table': {
          menuKeys: []
        },
        'video': {
          menuKeys: []
        },
      },
      MENU_CONF: {
        insertLink: {
          // checkLink: this.customCheckLinkFn, // 自定义校验链接
          parseLinkUrl: this.customParseLinkUrl, // 自定义转换链接 url
        }
      },
      EXTEND_CONF: {
        mentionConfig: {
          showModal: this.showMentionModal,
          hideModal: this.hideMentionModal,
        },
      },
    },
    mode: 'simple', // or 'simple'
    position: {
      range: '',
      selection: ''
    },
    currOptionList: [],
    userList: [],
    docList: [],
    isInAting: false, //正在输入@的内容，除非blur才可以解除
    searchKeyword: '',
    blurTimeId: '', //blur时候延迟隐藏弹窗
    enterChinese: false,
    urlDocMap:{}, // 缓存文档链接和文档信息
    pastePhotoCacheMap:{},  //缓存粘贴图片信息
    tempHtml: '',
    maxErrorNum: 0,
    isInserting: false,
    triggerVisible: false,
    onMountedSuccess:false,
    // AI识图
    topNumber: 0,
    bottomNumber: 0,
    top: '',
    right: '',
    bottom: '',
    left: '',
    isShowAIModal: false,
    fileStream: null,
  }
},
watch:{
  record: {
    immediate: true,
    deep: true,
    handler(val, ov) {
      if(val && !ov){
        // this.setAnchorToNode();
        const tempVal = val.replaceAll('<li>- ', '<li> ').replaceAll(' - ', '') // 兼容归档的无序列表，消息卡片显示问题
        this.$nextTick(() => {
          this.editor?.setHtml(tempVal)
        })
      }
    }
  },
},
mounted() {
  this.$nextTick(() => {
    setTimeout(() => {
      this.setCusIcon()
      this.onMountedSuccess=true
    }, 16);
  })
},
methods: {
  // AI识图
  handleReplaceImg(text) {
    this.handleSetAIContent(text, true)
  },
  handleAddText(text) {
    this.handleSetAIContent(text)
  },
  handleSetAIContent(text, isDelete = false) {
    const moveLength = text?.length + 1 || 9999999
    this.editor.restoreSelection() // 恢复选区
    isDelete && this.editor.deleteBackward()
    this.editor.insertBreak()
    this.editor.insertText(text)
    this.editor.move(moveLength) // 移动光标
    this.handleCloseAIModal()
  },
  handleShowAIImgPop(file) {
    this.fileStream = file
    let time = setTimeout(() => {
      this.isShowAIModal = true
      this.getPosition()
      clearTimeout(time)
      time = null
    }, 300);
  },
  handleCloseAIModal() {
    this.isShowAIModal = false
  },

  emojiChange(item)  {
    this.editor.focus()
    // this.focusHandler()
    this.triggerVisible=false
    this.$nextTick(() => {
      // this.editor.insertText(1)
      setTimeout(() => {
        this.insertImg(item.value,item.emojiType,true)
      },100)
    })
  },
  setCusIcon() {
    const svgPathMap = {
      0: 'M213.333333 115.626667c0-16.725333 13.568-30.293333 30.208-30.293334h287.317334a226.816 226.816 0 0 1 157.269333 390.314667 241.92 241.92 0 0 1-111.872 456.533333H243.626667a30.208 30.208 0 0 1-30.293334-30.208V115.541333z m85.333334 332.714666h232.192c75.178667 0 136.106667-61.013333 136.106666-136.106666C666.965333 236.970667 606.122667 170.666667 530.858667 170.666667H298.666667v277.589333z m0 90.709334V841.386667h277.589333a151.296 151.296 0 0 0 0-302.506667H298.666667z',
      1: 'M234.24 308.309333a217.685333 217.685333 0 0 1 60.16-144.896C342.016 112.554667 414.549333 85.333333 504.234667 85.333333c91.306667 0 161.28 24.32 207.616 72.106667 30.122667 29.354667 46.762667 59.477333 60.757333 110.762667 3.157333 11.861333 1.536 20.053333-3.925333 20.992l-79.36 14.762666c-6.058667 1.109333-7.850667-1.109333-10.410667-13.226666-6.314667-29.098667-13.909333-45.568-29.696-63.914667-31.317333-39.68-81.408-60.586667-144.469333-60.586667-102.485333 0-177.237333 59.562667-179.2 144.810667-0.853333 36.096 14.421333 67.413333 42.496 87.210667H250.709333a212.053333 212.053333 0 0 1-16.469333-89.941334z m379.989333 257.024H164.949333a22.784 22.784 0 0 1-22.784-22.698666v-34.56c0-12.544 10.24-22.698667 22.784-22.698667h701.184c12.544 0 22.784 10.154667 22.784 22.698667v34.56c0 12.544-10.24 22.698667-22.784 22.698666H755.626667c23.04 34.389333 40.96 83.370667 39.850666 130.304C791.893333 845.653333 677.12 938.666667 493.738667 938.666667c-122.709333 0-214.528-41.130667-261.546667-117.162667-16.298667-26.794667-24.746667-48.981333-33.792-90.026667-4.608-20.736-3.413333-23.04 13.909333-26.282666l60.16-11.605334c18.773333-3.669333 21.077333-1.706667 24.576 16.042667 7.936 41.216 19.370667 65.365333 42.325334 89.344 34.304 36.608 89.770667 55.637333 160.682666 55.637333 121.344 0 200.362667-59.306667 202.496-154.026666 1.109333-45.738667-13.056-79.872-42.666666-100.864-15.36-11.264-30.634667-22.784-45.653334-34.389334z',
      2: 'M632.490667 216.576L477.354667 807.509333H650.24a44.373333 44.373333 0 0 1 0 88.576H211.285333a44.373333 44.373333 0 0 1 0-88.576h172.970667L539.306667 216.661333H366.506667a44.373333 44.373333 0 0 1 0-88.661333h438.869333a44.373333 44.373333 0 0 1 0 88.576H632.32z',
      3: 'M314.026667 130.218667a42.24 42.24 0 0 0-42.24-42.410667 42.581333 42.581333 0 0 0-42.666667 42.410667V376.32C229.205333 555.52 340.224 687.274667 512 687.274667S794.709333 555.52 794.709333 376.234667V130.218667a42.496 42.496 0 0 0-84.821333 0V376.32c0 128.085333-75.264 226.133333-197.973333 226.133333-122.709333 0-197.973333-98.048-197.973334-226.133333V130.218667zM130.218667 828.586667a42.154667 42.154667 0 0 0-42.410667 42.154666 42.666667 42.666667 0 0 0 42.410667 42.666667h763.392a42.666667 42.666667 0 0 0 42.410666-42.666667 42.154667 42.154667 0 0 0-42.410666-42.154666H130.218667z',
      4: 'M149.333333 234.666667a64 64 0 1 0 0-128 64 64 0 0 0 0 128zM384 128a42.666667 42.666667 0 0 0 0 85.333333H938.666667a42.666667 42.666667 0 0 0 0-85.333333H384z m0 341.333333a42.666667 42.666667 0 0 0 0 85.333334H938.666667a42.666667 42.666667 0 0 0 0-85.333334H384zM341.333333 853.333333a42.666667 42.666667 0 0 1 42.666667-42.666666H938.666667a42.666667 42.666667 0 0 1 0 85.333333H384A42.666667 42.666667 0 0 1 341.333333 853.333333zM213.333333 512A64 64 0 1 1 85.333333 512a64 64 0 0 1 128 0z m-64 405.333333a64 64 0 1 0 0-128 64 64 0 0 0 0 128z',
      5: 'M179.285333 440.576h-77.653333l1.536-9.813333q2.901333-18.858667 2.901333-48.554667V179.541333h-9.813333q-17.664 0-38.570667 2.218667l-9.386666 1.024v-68.181333l9.813333 1.621333q13.568 2.133333 37.034667 2.133333h35.242666q21.333333 0 38.144-1.877333l10.496-1.109333-1.109333 10.496q-2.986667 29.866667-2.986667 62.378666V382.293333q0 28.16 2.986667 48.64l1.365333 9.728zM384 128a42.666667 42.666667 0 0 0 0 85.333333H938.666667a42.666667 42.666667 0 0 0 0-85.333333H384z m0 341.333333a42.666667 42.666667 0 0 0 0 85.333334H938.666667a42.666667 42.666667 0 0 0 0-85.333334H384zM67.84 906.154667q-32.170667 0-48.469333 1.877333l-10.496 1.194667 1.109333-10.581334q3.242667-31.573333 10.325333-51.2 16.042667-43.178667 54.954667-78.506666 19.114667-16.981333 64-47.018667 42.410667-29.013333 42.410667-49.322667 0-15.36-11.093334-24.149333-11.861333-9.472-33.28-9.472-29.525333 0-41.557333 18.688v0.170667l-0.170667 0.170666q-7.082667 9.984-10.410666 31.573334l-1.194667 8.192-72.106667-8.533334 1.706667-8.96q7.68-41.386667 28.16-65.365333 32-37.12 94.378667-37.12 53.248 0 84.650666 24.490667 32.426667 25.258667 32.426667 68.778666 0 39.424-30.037333 67.584-13.226667 12.288-65.194667 47.872-28.586667 20.138667-44.373333 37.205334-8.618667 9.216-14.677334 20.053333H192.853333q35.157333 0 54.698667-2.901333l9.813333-1.536v69.802666l-9.216-0.768q-26.624-2.218667-57.173333-2.218666H67.84zM384 810.666667a42.666667 42.666667 0 0 0 0 85.333333H938.666667a42.666667 42.666667 0 0 0 0-85.333333H384z',
      6: 'M292.949333 896C182.442667 896 85.333333 805.546667 85.333333 641.536c0-192 134.058667-414.890667 278.101334-529.92a37.888 37.888 0 0 1 51.285333 4.522667c17.066667 18.090667 13.653333 47.36-4.778667 64-102.912 92.586667-226.986667 292.437333-219.818666 386.304 9.130667-4.778667 54.528-22.016 119.466666-22.016 93.781333 0 164.096 73.642667 164.096 170.752A180.053333 180.053333 0 0 1 292.864 896z m464.896 0c-110.506667 0-207.530667-90.453333-207.530666-254.464 0-192 134.144-414.890667 278.101333-529.92a37.888 37.888 0 0 1 51.285333 4.522667c17.066667 18.090667 13.653333 47.36-4.693333 64-102.997333 92.586667-226.986667 292.437333-219.904 386.304 9.216-4.778667 54.528-22.016 119.466667-22.016 93.781333 0 164.096 73.642667 164.096 170.752A180.053333 180.053333 0 0 1 757.845333 896z',
      7: 'M457.898667 673.621333a225.024 225.024 0 0 1-87.978667-349.184l127.402667-157.44a224.938667 224.938667 0 0 1 349.696 283.306667L725.162667 600.746667l-3.925334-37.034667a155.562667 155.562667 0 0 0-25.088-70.144l80.981334-99.84A134.997333 134.997333 0 0 0 570.026667 220.245333l-2.730667 3.413334-127.402667 157.269333a134.997333 134.997333 0 0 0 78.848 217.429333l-60.842666 75.093334v0.085333zM525.653333 389.973333a225.024 225.024 0 0 1 87.893334 349.184L486.4 896.341333A224.938667 224.938667 0 1 1 134.314667 616.106667l2.304-2.816L258.56 462.762667l3.754667 36.949333c2.56 25.088 11.264 49.152 25.173333 70.229333L206.506667 669.952A134.997333 134.997333 0 0 0 416.426667 839.850667l127.317333-157.354667a134.826667 134.826667 0 0 0-78.762667-217.429333l60.757334-75.093334z',
    }
    const domArr = document.getElementsByClassName('w-e-bar-item')
    Array.from(domArr)?.forEach((item, index) => {
      index = index % 8
      const svgElement = item.getElementsByTagName('path')
      Array.from(svgElement)?.forEach(path => {
        path.setAttribute("d", svgPathMap[index])
      })
    })
  },
  onCreated(editor) {
    this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
  },
  onChange(editor) {
    if (this.isShowAIModal) {
      this.handleCloseAIModal()
    }
    this.updateRecordHtml()
    this.$emit('updataEmptyStatus', this.editor.isEmpty())
  },
  showMentionModal() {
    this.isShowModal = true
  },
  hideMentionModal() {
    this.isShowModal = false
    let time = setTimeout(() => {
      this.isInserting = false
      clearTimeout(time)
      time = null
    }, 500);
  },
  // customCheckLinkFn(text, url) {
  //   console.log('0000', text, url)
  //   if (!url) {
  //     return
  //   }
  //   if (url.indexOf('http') !== 0) {
  //     return '链接必须以 http/https 开头'
  //   }
  //   return true
  // },
  customParseLinkUrl(url) {
    // console.log('1111', url)
    if (url.indexOf('http') !== 0) {
      return `https://${url}`
    }
    return url
  },
  insertMention(item) {
    this.isInserting = true
    const mentionNode = {
      type: 'mention', // 必须是 'mention'
      value: item.name,
      info: {...item},
      children: [{ text: '' }], // 必须有一个空 text 作为 children
    }
    const editor = this.editor
    if (editor) {
      editor.restoreSelection() // 恢复选区
      editor.deleteBackward('character') // 删除 '@'
      editor.insertNode(mentionNode) // 插入 mention
      editor.move(1) // 移动光标
      let time = setTimeout(() => {
        this.isInserting = false
        clearTimeout(time)
        time = null
      }, 400);
    }
  },
  focusHandler() {
    this.editor.focus()
    this.$nextTick(() => {
      const editor = this.editor
      if (editor) {
        // editor.focus()
        editor.restoreSelection() // 恢复选区
        editor.move(10000000) // 移动光标
      }
    })

    // this.$refs.editor.focus()

    // // 设置光标位置
    // if (window.getSelection) {
    //   let sel = window.getSelection();    
    //   let range = sel.getRangeAt(0);
    //   // IE9 and non-IE
    //   if (sel.getRangeAt && sel.rangeCount) {
    //     let el = this.$refs.editor;
    //     let frag = document.createDocumentFragment(), node, lastNode;
    //     while ((node = el.firstChild)) {
    //       lastNode = frag.appendChild(node);
    //     }
    //     range.insertNode(frag);
    //     // Preserve the selection
    //     if (lastNode) {
    //       range = range.cloneRange();
    //       range.setStartAfter(lastNode);
    //       range.collapse(true);
    //       sel.removeAllRanges();
    //       sel.addRange(range);
    //     }
    //   }
    // } else if (document.selection && document.selection.type != "Control") {
    //   // IE < 9
    //   document.selection.createRange();
    // }
  },
  editorFocusHandler() {
    this.$emit('editorFocus')
  },
  editorBlurHandler() {
    if (this.isShowModal || this.isInserting || this.rejectBlur || this.triggerVisible) return
    let time = setTimeout(() => {
      this.$emit('editorBlur')
      clearTimeout(time)
      time = null
    }, 300);
  },
  getPosition() {
    const viewWidth = window.innerWidth
    const viewHeight = window.innerHeight
    const modalHeight = 300 //  设置 modal 特殊边界高度 300px
    const domSelection = document.getSelection()
    const domRange = domSelection?.getRangeAt(0)
    if (domRange == null) return
    const rect = domRange.getBoundingClientRect()

    // 定位 modal
    this.top = `${rect.top + 28}px`
    this.topNumber = `${rect.top + 28}`
    this.left = `${rect.left + 5}px`
    if (viewWidth < rect.left + 560) { // 560  最大宽度是 560px
      this.right = '64px'
      this.left = 'auto'
    }
    if (viewHeight < rect.top + modalHeight) {
      this.bottomNumber =  viewHeight - rect.top + 40 // 40 图片高度 40px
      this.bottom = viewHeight - rect.top + 40 + 'px'
      this.top = 'auto'
      this.topNumber = 0
    }
  },
  setPhotoCacheMap(file, tmpFileName) {
    this.pastePhotoCacheMap[tmpFileName] = file;
  },
  customPaste(editor, event) {
    this.tempHtml = this.editor.getHtml()
    var text;
    var clp = (event.originalEvent || event).clipboardData;
    if(this.enablePasteImg && (clp.files && clp.files.length > 0)){
      let file = clp.files[0];
      let tmpFileName = 'img_' + file.lastModified;
      this.setPhotoCacheMap(file, tmpFileName)
      if (/^image\//.test(file.type)) {
        let reader = new FileReader();
        reader.onload = (event)=>{
          let src = event.target.result;
          this.insertImg(src, tmpFileName);
          // AI识图
          this.handleShowAIImgPop(file)
        }
        reader.readAsDataURL(file)
      }
      event.preventDefault()

      return false
    }else{
      // return true
      // // 兼容chrome或firefox
      text = clp.getData('text/plain') || "";
      // document.execCommand('insertText', false, text);
      this.replaceDocLink(text);
      return true
    }
  },
  getPastePhotoMap(){   //外部使用，获取粘贴图片信息
    return this.pastePhotoCacheMap;
  },
  getFileIconByType(file){
    switch (file.fileType) {
      case 'docx':
      case 'docs':
      case 'doc':
        return 'icon-a-wendang1x';
      case 'sheet':
        return 'icon-a-biaoge1x';
      case 'bitable':
        return 'icon-a-duoweibiaoge1x';
      case 'mindnote':
        return 'icon-siweibiji';
      case 'file':{
        if(file.fileName.indexOf('.') == -1){ 
          return 'icon-a-weizhi1x'
        }
        let tmpArr = file.fileName.split('.');
        let type = tmpArr[tmpArr.length - 1];
        switch (type) {
          case 'xlsx':
          case 'xls':
            return 'icon-xlsx';
          case '':
          case 'docx':
          case 'docs':
          case 'doc':
            return 'icon-word';
          case 'pdf':
            return 'icon-pdf';
          case 'txt':
            return 'icon-txt';
          case 'png':
          case 'img':
          case 'jpg':
          case 'jpeg':
            return 'icon-img';
          default: 
            return 'icon-a-weizhi1x';
        }
      }
      default:
        return 'icon-a-weizhi1x';
    }
  },
  reset() {
    this.editor.clear()
    setTimeout(() => {
      this.editor.setHtml('')
    });
  },
  // 更新上层html
  updateRecordHtml(){
    // 需要缓存的文本
    const memoryHtml = this.editor.getHtml().replace(/^(<div><br><\/div>)+/g,'')
        .replace(/(<div><br><\/div>)+$/g,'')
    // 获取纯文本
    let tmpHtml = this.editor.getHtml().replace(/^(<div><br><\/div>)+/g,'')
        .replace(/(<div><br><\/div>)+$/g,'')
        .replace(/<img[^<>]*?alt="([\S]*)"[\s\S]*?>/g, function(a,b){
          let str=a
          if(b){
            if(b.startsWith("img_")){
              // 粘贴的图片
              str=b ? `<img class="local-img" src="$\$${b}$$"><br>` : a
            }else{
              //表情图片 等
              str=`${a.slice(0, 5)}${'class="yw-emjio" '}${a.slice(5)}`;
            }
          }
        return str
    });
    // //替换换行为<br/>,同时去掉<>标签
    let text; // = tmpHtml.replace(/<[^>]+>/g,'').replace(/^\n+/g,'');
    if (this.isRemark) {
      text = tmpHtml.replace(/<span[\s\S]*?>([\s\S]*?)<\/span>/g,function(a,b){ 
        return b; 
      }).replace(/<img[^<>]*?>/g, function(a,b,c){
        return '[图片]'
      }).replace(/<a[\s\S]*?href="([\S]*)"[\s\S]*?>([\s\S]+?)<\/a>/g, function(a,b,c){
        return `${b}`;
      }).replace(/^\n+/g,'').replace(/<div>/g,'\n').replace(/<p>/g,'\n').replace(/<[^>]+>/g,'').replace(/&nbsp;/g, ' ');
    } else {
      // let index = 0
      text = tmpHtml.replace(/<span[\s\S]*?>([\s\S]*?)<\/span>/g,function(a,b){ 
        return b; 
      }).replace(/<img[^<>]*?>/g, function(a,b,c){
        if(a.indexOf('<img class="local-img"')>-1){
          // 本地图片 粘贴
          return '[图片]'
        }else{
          //表情图片 等
          const reg = /alt\s*=\s*[\'\"]?(.*?)[\'\"][^>]/si
          const altArr = reg.exec(a)
          if(altArr){
            let alt=altArr[1]
            
            let findItem=emojiType.find((item)=>item.emojiType==alt)
            return `[${findItem?.text||'图片'}]`
          }
         
        }
       
      }).replace(/<a[\s\S]*?href="([\S]*)"[\s\S]*?>([\s\S]+?)<\/a>/g, function(a,b,c){
        return `[${c}](${b})`;
      }).replace(/<div>/g,'\n').replace(/<p>/g,'\n')
      .replace(/<li> /g,'\n- ').replace(/<\/li>/g,'') // 处理归档的无序列表，消息卡片显示问题
      // .replace(/<li>/g, `\n${index++}. `).replace(/<\/li>/g,'') // 有序。这个有问题，没法兼容归档和我们自定义的富文本，有序无序都有问题
    }

    // 兼容飞书消息卡片 UI
    const msgContent = this.replaceHtmlTags(text)
    // 兼容飞书消息卡片 UI end

    text = text.replace(/<[^>]+>/g,'').replace(/&nbsp;/g, ' ').replace(/^\n+/g,'');
    
    // console.log('【updateRecordHtml】', this.editor.getHtml(), tmpHtml);
    this.$emit('updateRecordHtml', tmpHtml, text, memoryHtml, msgContent)
  },
  replaceHtmlTags(text) {
    let msgContent = text.replace(/<li[^>]*style="[^"]*"[^>]*>/gi, '<li>')
    let olRegex = /<ol>([\s\S]*?)<\/ol>/g;
    msgContent = msgContent.replace(olRegex, function(match, p1) {
      let count = 1
      let temp = `${p1.replace(/<li>/g, (match, p1) => `\n${count++}. `)}`
      temp = temp.replace(/<\/li>/gi, '\n')
      return temp
    });
  
    // 替换 ul 下的 li 为连字符
    let ulRegex = /<ul>([\s\S]*?)<\/ul>/g;
    msgContent = msgContent.replace(ulRegex, function(match, p1) {
      let temp = `${p1.replace(/<li>/g, (match, p1) => `\n- `)}`
      temp = temp.replace(/<\/li>/gi, '\n')
      return temp
    });

    // 替换加粗
    msgContent = msgContent.replace(/<strong>/gi, '**').replace(/<\/strong>/gi, '**')
    // 替换斜体
    msgContent = msgContent.replace(/<em>/gi, '*').replace(/<\/em>/gi, '*')
    // // 替换删除线
    msgContent = msgContent.replace(/<s>/gi, '~~').replace(/<\/s>/gi, '~~')
    // 替换下划线 不生效
    // msgContent = msgContent.replace(/<u>/gi, '_').replace(/<\/u>/gi, '_')
    // 去掉默认 p 标签
    msgContent = msgContent.replace(/<p>/gi, '').replace(/<\/p>/gi, '\n')
    
    // 去掉 htmlbiaoqian
    msgContent = msgContent.replace(/<[^>]+>/g,'').replace(/&nbsp;/g, ' ').replace(/^\n+/g,'');
    msgContent = msgContent.replace(/\n+/g,'\n')

    return msgContent
  },
  handleEditBlur(e){
    if(this.showFlag == 'visible'){
      this.blurTimeId = setTimeout(this.closeEdit, 300);
    }
  },
  closeEdit(){
    this.isInAting = false;
    // this.showFlag = 'hidden';
    this.$emit('updataShowFlag', false)
    this.searchKeyword = '';
  },
  createEditor() {
    let editor = this.$refs.editor;
    this.editor = editor;
    let _this = this;

    editor.addEventListener('compositionstart', function(e){
      _this.enterChinese = true;
    });
    editor.addEventListener('compositionend', function(e){
      _this.enterChinese = false;
      _this.handleEditInput(e);
    })
    editor.addEventListener('input', this.handleEditInput);
    editor.addEventListener('keydown', this.handleKeydown);
    editor.addEventListener('blur', this.handleEditBlur);
    editor.addEventListener('click', this.handleClick);

    editor.addEventListener('paste', this.handlePaste); // 粘贴样式的过滤

    // editor.style.height = this.minHeight;
    editor.style.minHeight = this.minHeight;
    editor.style.maxHeight = this.maxHeight;
    
    if(this.record){
      editor.innerHTML = this.record;
      this.updateRecordHtml();
    }
  },
  handleClick(evt){
    if(evt.target && evt.target.className?.indexOf('local-img') > -1){
      let tmpNode = evt.target.parentNode;
      while(tmpNode){
        if(tmpNode.className.indexOf('root-text') > -1){
          break;
        }else{
          tmpNode = tmpNode.parentNode;
        }
      }
      let imgNode = tmpNode.getElementsByClassName('local-img');
      let tmpIndex = Array.from(imgNode).findIndex((ii)=>{ return ii.src == evt.target.src })

      this.$emit('previewImg', Array.from(imgNode).map((ii)=>{ return ii.src }), tmpIndex);
    }
  },
  async handlePaste(event) {
    event.preventDefault();
    var text;
    var clp = (event.originalEvent || event).clipboardData;
    //归档先不不支持粘贴图片
    if(this.from != 'message' && this.enablePasteImg && (clp.files && clp.files.length > 0)){
      let file = clp.files[0];
      let tmpFileName = 'img_' + file.lastModified;
      this.setPhotoCacheMap(file, tmpFileName)
      if (/^image\//.test(file.type)) {
        let reader = new FileReader();
        reader.onload = (event)=>{
          let src = event.target.result;
          this.insertImg(src, tmpFileName);
        }
        reader.readAsDataURL(file)
      }
    }else{
      // 兼容chrome或firefox
      text = clp.getData('text/plain') || "";
      document.execCommand('insertText', false, text);
      this.replaceDocLink(text);
    }
  },
  insertImg(srcUrl, fileId,isEmoji=false) {
    const mentionNode = {
      type: 'image', // 必须是 'mention'
      src: srcUrl,
      alt: fileId,
      // href:'customEmoji',//由于添加class 不支持 class使用了href
      children: [{ text: '' }], // 必须有一个空 text 作为 children
    }
    if(isEmoji){
      mentionNode.href='customEmoji'
    }
    const editor = this.editor
    if (editor) {
      editor.restoreSelection() // 恢复选区
      editor.insertNode(mentionNode) // 插入 mention
      editor.move(1) // 移动光标
    }
  },

  //如果没有node, 默认光标设置到末尾
  setAnchorToNode(node){
    let range = document.createRange();
    if(node){
      range.selectNode(node);
    }else{
      let nodeList = this.editor.childNodes;
      if(nodeList.length < 1) return; //如果没有指定节点，并且editor无内容，则直接返回
      range.selectNode(nodeList[nodeList.length - 1]); 
    }
    range.collapse(false);

    let selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  },
  isHttpUrl(url) {
    try{
      new URL(url);
      return true;
    }catch(e){
      return false;
    }
  },
  async replaceDocLink(text){
    if(!this.isHttpUrl(text)) return;

    let fileInfo;
    //如果是 链接，判断是否文档链接
    if(this.urlDocMap[text]){
      fileInfo = this.urlDocMap[text];
    } else {
      let fileList = await searchFilesByUser({
        data:{
          searchKey: text,
          currentPage: 1,
          pageSize: 20
        }
      })
      if(fileList.length == 1){
        this.urlDocMap[text] = fileList[0];
        fileInfo = fileList[0]
      }
    }

    if(fileInfo){
      let time = setTimeout(() => {
        this.deleteCurText(text) // 删除复制的链接内容
        this.$nextTick(() => { // 这里一定要nextTick，不然会删除新insertMention进去的文件
          this.insertMention({
            name: fileInfo.fileName,
            type: 'file',
            fileInfo: fileInfo,
          })
          clearTimeout(time)
          time = null
        })
      }, 100);
    }
  },
  deleteCurText(text) {
    // 因为链接插入多个空格 所以length + 1
    const length = text.length + 1
    for (let index = 0; index < length; index++) {
      this.editor.deleteBackward()
    }
  },
  handleKeydown (e) {
    if(this.isInAting){
      //如果点左右，立刻直接去掉展示@
      if(e.code =='ArrowUp' || e.key == 'ArrowUp'){
        e.preventDefault();
        return;
      }else if(e.key == 'ArrowDown' || e.code == 'ArrowDown'){
        e.preventDefault();
        return;
      }else if(e.code =='ArrowLeft' || e.key == 'ArrowLeft' || e.key == 'ArrowRight' || e.code == 'ArrowRight'){
        this.closeEdit();
        e.preventDefault();
        return;
      }else if(e.code == 'Enter' || e.key == 'Enter'){
        e.preventDefault();
        return;
      }
    }
    let selection = getSelection()
    //判断是删除
    if (e.code === 'Backspace' || e.key === 'Backspace') {
      let range = selection.getRangeAt(0)
      let removeNode = null
      if (this.browserType === 'Chrome') {
        if (range.startContainer.textContent.length === 1 && range.startContainer.textContent.trim() === '') {
          removeNode = range.startContainer.previousElementSibling
        }
        if (range.startContainer.parentNode.className === 'at-text') {
          e.preventDefault ? e.preventDefault() : e.returnValue = false
          removeNode = range.startContainer.parentNode
        }
      }
      if (removeNode) {
        removeNode.parentNode.removeChild(removeNode)
      }
      this.showFlag = 'hidden'
      this.$emit('updataShowFlag', false)
    }
  },
  handleSelect (item) {
    this.closeEdit();
    const {name} = item
    this.showFlag = 'hidden'
    this.$emit('updataShowFlag', false)
    //获取选区对象
    let selection = this.position.selection
    let range = this.position.range

    let spanNode1;
    //如果选择了人
    if(item.type == 'user'){
      // 生成需要显示的内容，包括一个 span 和一个空格。
      spanNode1 = document.createElement('span')
      spanNode1.className = 'at-text'
      spanNode1.innerHTML = '@' + name
      spanNode1.dataset.id = item.userInfo.id
      //  设置@人的节点不可编辑
      spanNode1.contentEditable = false
    }else if(item.type =='file'){
      // 生成需要显示的内容，包括一个 span 和一个空格。
      spanNode1 = document.createElement('a')
      spanNode1.className = 'at-text at-file'
      spanNode1.setAttribute('href', item.fileInfo.url);
      spanNode1.setAttribute('target', '_blank');
      spanNode1.innerHTML = `@` + name
      // spanNode1.innerHTML = `<i class="iconfont ${this.getFileIconByType(item.fileInfo)}" style="font-size: 12px" />` + name
      spanNode1.dataset.fileToken = item.fileInfo.fileToken
      spanNode1.dataset.fileName = item.fileInfo.fileName
      spanNode1.dataset.fileTags = item.fileInfo.fileTags ||'9999'
      spanNode1.dataset.fileType = item.fileInfo.fileType
      //  设置@人的节点不可编辑
      spanNode1.contentEditable = false
    }

    let spanNode2 = document.createElement('p');
    spanNode2.innerHTML = '&nbsp;';
    // 将生成内容打包放在 Fragment 中，并获取生成内容的最后一个节点，也就是空格。
    //创建一个新的空白的文档片段
    let frag = document.createDocumentFragment(),
        node, lastNode;
    frag.appendChild(spanNode1)
    lastNode = frag.appendChild(spanNode2.firstChild)

    //先定位@符号
    // let editRange = this.editor.selection._currentRange;
    let editRange = this.position.range;
    let selectContainer = editRange.commonAncestorContainer

    if(selectContainer.nodeName == '#text'){  //如果是普通文本
      let oldVal = Array.from(selectContainer.nodeValue || '');

      let offsetIndex = editRange.endOffset;
      let atIndex = oldVal.lastIndexOf('@', offsetIndex)

      if(atIndex > -1){
        
        let child = editRange.commonAncestorContainer;
        let beforeText = oldVal.slice(0, atIndex).join('');
        let afterText = oldVal.slice(offsetIndex).join('');
        
        if(beforeText){
          child.before(beforeText);
        }
        if(afterText){
          child.after(afterText);
        }
        child.replaceWith(frag);
        // parent.replaceChildren(...tmpArr);
      }
    }

    // 将 Fragment 中的内容放入 range 中，并将光标放在空格之后。
    // range.insertNode(frag)
    selection.collapse(lastNode, 1)

    //将当前的选区折叠到最末尾的一个点
    selection.collapseToEnd();

    this.updateRecordHtml();
  },
},
unmounted() {
  const editor = this.editor
  if (editor == null) return
  editor?.destroy() // 组件销毁时，及时销毁编辑器
  this.editor = null
}
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>

<style lang="scss" scoped>

.emoji-icon{
  cursor: pointer;
  color: #646A73;
  span{
    color: #1F2329;
  }
}
.at-someone-content {
// flex:1;
width: 100%;
.hide-tool-bar {
  visibility: hidden;
  position: absolute;
  top: -10000px;
  left: -10000px;
  z-index: -1;
}
:deep() {
  .w-e-toolbar {
    border-bottom: 1px solid #DBE0E3;
    .w-e-menu-tooltip-v5 {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      transition: all .2s;
      padding: 0;
      &.active {
        background: #EEF4FF;
        svg {
          fill: #3272FE;
        }
      }
      svg {
        width: 18px;
        height: 18px;
        fill: #646A73;
      }
    }
  }
  .w-e-max-length-info {
    display: none;
  }
  // .w-e-hover-bar {
  //   display: none;
  // }
  .w-e-image-dragger {
    display: none;
  }
  .w-e-scroll {
    overflow: hidden !important;
    div {
      padding: 0;
    }
    ol {
      li {
        s {
          margin-left: 8px;
        }
        &::marker {
          color: #3272FE;
          margin-right: 8px;
        }
      }
    }
    blockquote {
      background: transparent;
      border-left: 2px solid #BBBFC3;
      display: block;
      font-size: 100%;
      line-height: 22px;
      margin: 6px 0;
      padding: 0 0 0 8px;
      color: #646A73;
    }
    span[contenteditable="false"] {
      color: #3272FE;
      margin: 0 3px;
      padding: 0 !important;
      background: none !important;
    }
    .w-e-image-container {
      width: auto!important;
     height: auto!important;
      &:hover {
        box-shadow: none;
      }
      img {
        height: 40px!important;
        object-fit: cover;
      }
      img[data-href="customEmoji"]{
        height: 22px !important;
        vertical-align: bottom;
      }
    }
    
  }
  .w-e-text-container {
    min-height: 26px;
    max-height: 200px;
    overflow-y: auto;
    padding: 0 12px;
    box-sizing: border-box;
    line-height: 22px !important;
    word-break: break-all;
    .w-e-selected-image-container {
      overflow: visible;
    }
    p {
      margin: 0;
      padding-left: 0;
    }
    * {
      font-family: PingFang SC;
      font-size: 14px;
      // font-weight: normal;
      // color: #1F2329;
    }
  }
}
}
.top-team-search-empty {
display: flex;
height: 40px;
flex-direction: column;
align-items: center;
justify-content: center;
small {
  font-size: 12px !important;
  color: #c1c4cb;
  margin-top: 6px !important;
  display: block;
}
}
.placeholder-editor {
position: relative;
&::after {
  content: attr(data-attr);
  position: absolute;
  top: -1px;
  left: 0;
  // line-height: v-bind('minHeight');
  line-height: 28px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #86909C;
  z-index: 0;
}
&.active,
&:focus-within  {
  &::after {
    content: '';
  }
}
}
</style>
<style lang="scss">
.at-someone-content {
  .w-e-modal {
    position: fixed;
    // top: v-bind(top) + 'px' !important;
    // left: v-bind(left) + 'px' !important;
    top: auto !important;
    left: auto !important;
    padding: 28px 16px 14px 16px;
    // padding: 14px 16px;
    border-radius: 6px;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #DEE0E3;
    /* 下拉菜单 */
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    bottom: v-bind(top) !important;
    .btn-close {
      // display: none;
      right: 14px;
      top: 1px;
    }
    .button-container {
      display: flex;
      flex-direction: row-reverse;
      margin: 0;
      button {
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;
        background: #3272FE;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: normal;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        border: 0;
      }
    }
    .babel-container {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        flex-shrink: 0;
        margin-bottom: 0;
        margin-right: 8px;
      }
      input {
        border-radius: 6px;
        padding: 5px 8px;
        box-sizing: border-box;
        font-family: 苹方-简;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #1F2329;
        overflow: hidden;
        transition: .2s;
        &:hover {
          border-color: #3272FE;
        }
        &:focus-within {
          border-color: #3272FE;
        }
      }
    }
  }
}

.w-e-text-placeholder {
  // left: 12px;
  // top: -2px;
  // height: 26px;
  // line-height: 26px;
  // font-family: PingFangSC-Regular;
  // font-size: 14px;
  // color: #86909C;
  // font-style: normal;
  left: 12px;
  top: 0;
  height: 22px;
  line-height: 22px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #86909C;
  font-style: normal;
}
</style>